
























































import Vue from "vue";
import Search from "@/components/Search.vue";
import MenuFooter from "@/views/layout/footer/MenuFooter.vue";
import { Component } from "vue-property-decorator";
@Component({
  components: {
    Search,
    MenuFooter,
  },
})
export default class Faq extends Vue {
  listFaq = [
    {
      name: "Pengiriman",
      id: "1",
      description:
        "<p><b>Bagaimana Cara Pengiriman ?</b></p> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p><p><b>Bisa Kirim dengan Apa Saja ?</b></p> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>",
    },
    {
      name: "Pembelian?",
      id: "2",
      description:
        "<p><b>Bagaimana Cara Pengiriman ?</b></p> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p><p><b>Bisa Kirim dengan Apa Saja ?</b></p> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>",
    },
    {
      name: "Cara Pengaduan?",
      id: "3",
      description:
        "<p><b>Bagaimana Cara Pengiriman ?</b></p> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p><p><b>Bisa Kirim dengan Apa Saja ?</b></p> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>",
    },
    {
      name: "Pengajuan Pengambilan Barang?",
      id: "4",
      description:
        "<p><b>Bagaimana Cara Pengiriman ?</b></p> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p><p><b>Bisa Kirim dengan Apa Saja ?</b></p> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>",
    },
  ];
}
